.monthlyUsageReport {
  margin: auto;
  max-width: 1200px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.monthlyUsageReport button.retryButton {
  text-transform: none;
  background-color: lightblue;
  width: 10rem;
  height: 2.5rem;
}

.monthlyUsageReport button.executeButton {
  text-transform: none;
  background-color: red;
  color: black;
  width: 20rem;
  max-width: 95%;
  height: 2.5rem;
}

.monthlyUsageReport button.downloadButton {
  text-transform: none;
  background-color: lime;
  color: black;
  width: 20rem;
  max-width: 95%;
  height: 2.5rem;
}

.monthlyUsageReport .reportParam {
  width: 300px
}

.monthlyUsageReport .csvTable th,
.monthlyUsageReport .csvTable td {
  overflow: hidden;
}